/*
        SUMMARY
            0 - Const / Variables
            1 - Function
*/

import 'slick-carousel/slick/slick';

/*---------------------/ 0 - Const - Variables /-------------------*/


/*---------------------/ 1 - Functions /-------------------*/

$('.autoplay').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    infinite: false,
    responsive: [{
        breakpoint: 768,
        settings: {
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '50px',
            infinite: true,
        }
    }
]
});

$(document).ready(function() {
    // Executa a função apenas se a largura da tela for menor ou igual a 768px
    if ($(window).width() <= 768) {
        $('.categorias_grid').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            infinite: true,
            centerMode: true,
            centerPadding: '50px',
        });
    }
});

